import { memo, useMemo, useCallback } from 'react';
import { Link } from '@components/ui';

import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';

import { ContentType } from '@utils/contentType';
import { IArticleProduct } from '@models/article.interface';
import { Favorite } from '@components/core';
import { VideoMask, Img } from './styles';
import ArticlesIcon from '../../../../assets/svg/document.svg';
import VideoIcon from '../../../../assets/svg/circle-play.svg';
import PodcastIcon from '../../../../assets/svg/voice.svg';
import RoundedPlayIcon from '../../../../assets/svg/rounded-play.svg';

export interface ICardProps {
  id: string;
  url: string;
  background?: string;
  thumbnail?: string;
  time: string | number;
  title: string;
  type: ContentType;
  categories?: {
    name: string;
    id: string;
  }[];
  store_product?: IArticleProduct;
  slug: string;
  onClickTracking?: () => void;
}

const Card: React.FC<ICardProps> = ({
  id,
  background,
  url,
  time,
  title,
  type,
  store_product,
  slug,
  onClickTracking,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: { position: 'relative' },
    content: {
      display: 'flex',

      alignItems: 'flex-end',
      justifyContent: 'space-between',
      margin: '20px 20px 20px 0px',
      whiteSpace: 'normal',
      position: 'relative',
      cursor: 'pointer',

      height: 370,
      width: 204,

      '& div': {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        padding: 20,
        zIndex: 1,

        '& span': {
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Barlow',
          letterSpacing: 0,
          fontWeight: 400,
          color: theme.palette.common.white,
          paddingBottom: 11,
          lineHeight: '19px',
          fontSize: 16,

          '& svg': {
            height: 15,
            width: 15,
            marginRight: 8,
            '& path.a': {
              fill: 'white',
            },
          },
        },
      },
    },
    backdrop: {
      height: 370,
      width: 204,
      borderRadius: 10,
      background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
      position: 'absolute',
    },
    title: {
      margin: 0,
      fontFamily: 'Barlow',
      letterSpacing: 0,
      fontWeight: 500,
      color: theme.palette.common.white,
      lineHeight: '23px',
      fontSize: 21,
    },
    containerFavorite: {
      position: 'absolute',
      right: 30,
      top: 30,
      zIndex: 3,
    },
  });

  const contentType = useMemo(
    () => ({
      article: {
        icon: <ArticlesIcon />,
        path: '/artigos',
      },
      video: {
        icon: <VideoIcon />,
        path: '/aulas/destaque',
      },
      podcast: {
        icon: <PodcastIcon />,
        path: '/podcast',
      },
    }),
    [],
  );

  const handleContent = useCallback(
    (value: string) => {
      if (value) {
        return contentType[value];
      }
      return undefined;
    },
    [contentType],
  );
  const {
    content,
    container,
    title: titleClass,
    backdrop,
    containerFavorite,
  } = useStyles();

  return (
    <div className={container}>
      <div className={containerFavorite}>
        <Favorite
          buttonType="mobile"
          contentId={id}
          type={type}
          contentRelatedProduct={store_product}
          contentSlug={slug}
        />
      </div>

      <Link href={url}>
        {type === 'video' && (
          <Box position="relative" zIndex="2">
            <VideoMask>
              <RoundedPlayIcon />
            </VideoMask>
          </Box>
        )}

        <Box className={content} onClick={() => onClickTracking()}>
          <Box className={backdrop} />

          <div>
            <span>
              {handleContent(type)?.icon}
              {time}
            </span>
            <Typography className={titleClass}>{title}</Typography>
          </div>
        </Box>
        <Box position="relative">
          <Box position="absolute" width="204px" height="370px" top="-390px">
            <Img
              alt="Background Carousel"
              src={background || '/share.webp'}
              layout="fill"
              objectFit="cover"
              quality="50"
            />
          </Box>
        </Box>
      </Link>
    </div>
  );
};

export default memo(Card);
